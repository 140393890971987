<script>
import { mapActions } from 'vuex'
import { required, sameAs } from 'vuelidate/lib/validators'
import Action from '@/components/Action'
import Icon from '@/components/Icon'
import InputField from '@/components/InputField'

export default {
  name: 'ChangeProfilePassword',

  components: {
    Action,
    Icon,
    InputField,
  },

  data () {
    return {
      formData: {
        oldPassword: null,
        password: null,
        repeatPassword: null,
      },
      loading: false,
    }
  },

  validations: {
    formData: {
      oldPassword: {
        required,
      },
      password: {
        required,
      },
      repeatPassword: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },

  methods: {
    ...mapActions('auth', ['changePassword']),
    ...mapActions(['setFeedback']),

    clearForm () {
      this.formData = {
        password: null,
        repeatPassword: null,
      }
    },

    handleSubmit () {
      this.$v.$touch()
      if (this.$v.$invalid) { return }
      this.loading = true
      this.changePassword(this.formData).then((retorno) => {
        this.loading = false
        if (retorno.message === 'Senha antiga nao encontrada') {
          this.setFeedback({ title: 'Senha antiga nao encontrada!', message: '', type: 'success' })
        } else if (retorno.message === 'OK') {
          this.setFeedback({ title: 'Senha alterada com sucesso!', message: 'Faça seu login com a nova senha', type: 'success' })
          this.$router.push({ name: 'home' })
        } else {
          this.setFeedback({ title: 'Erro de conexão', message: 'Tente novamente mais tarde', type: 'success' })
        }
      }).catch(() => {
        this.loading = false
        this.clearForm()
      })
    },
  },
}
</script>

<template>
  <div :class="$classes['home']">
     <div :class="$classes['box']">
      <h1 :class="$classes['title']">Alterar senha</h1>
      <form @submit.prevent="handleSubmit">
        <input-field
        v-model="formData.oldPassword"
        label="Senha antiga"
        placeholder="Escreva aqui"
        type="password"
        :validation="$v.formData.oldPassword"
        :tabindex="1"
      />
        <input-field
          v-model="formData.password"
          label="Nova senha"
          placeholder="Escreva aqui"
          type="password"
          :validation="$v.formData.password"
          :tabindex="2"
        />
        <input-field
          v-model="formData.repeatPassword"
          label="Repetir nova senha"
          placeholder="Escreva aqui"
          type="password"
          :validation="$v.formData.repeatPassword"
          :tabindex="3"
        />
        <div :class="$classes['form-actions']">
          <action
            full-width
            variant="contained"
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
            :tabindex="4"
          >
            Salvar
          </action>
        </div>
      </form>
      <action
        variant="flat"
        color="secondary"
        uppercase
        :to="{ name: 'home' }"
      >
        <icon name="long-arrow-alt-left" btnIcon/>
        Voltar
      </action>
    </div>
  </div>
</template>

<style module>
.home {
  height: 100%;
  background: url('~@/assets/images/home.png') no-repeat center;
  background-size: cover;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 380px;
  background: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px 35px;
}

.logo {
  display: block;
  margin: 0 auto 20px;
  width: 100%;
  height: 110px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  margin-bottom: 30px;
  color: rgba(41, 41, 41, 0.8);
}

.form-actions {
  margin-top: 30px;
  margin-bottom: 25px;
}

@media (max-width: 767px) {
  .home {
    padding: 20px;
  }

  .box {
    width: 100%;
    max-width: 380px;
  }
}
</style>
